* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.ColorPalette {
  /* border: 2px solid red; */
  position: fixed;
  height: 6rem;
  width: 44rem;
  margin-left: 37%;
  top: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 10;
}

.btn {
  border: none;
  border-radius: 5px;
  font-size: 2rem;
  cursor: pointer;
}

.btn:hover {
  transform: scale(0.95);
}

.FunctionsContainer {
  /* border: 2px solid red; */
  width: 400px;
  padding: 1.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.range {
  width: 120px;
  height: 9px;
  -webkit-appearance: none;
  background: rgba(62, 62, 62, 0.442);
  outline: none;
  border-radius: 15px;
  overflow: hidden;
  /* box-shadow: inset 0 0 5px rgba(0, 0, 0, 1); */
}
.range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #ff3700;
  cursor: pointer;
  border: 4px solid rgb(81, 223, 255);
  box-shadow: -407px 0 0 400px #fd4300;
}
